<template>
  <div>
    <div v-if="loading">
      <div
        class="border border-blue-light-one rounded-xl h-[80px] w-full pl-5 bg-white text-base flex items-center mt-5"
      >
        <NuxtImg
          width="40px"
          src="/images/loaders/rolling-blue-200px.gif"
        />
        <h5 class="mb-0 pl-3">Searching postcode</h5>
      </div>
    </div>

    <div v-else>
      <div class="relative z-10 w-full max-w-lg mt-5">
        <button
          @click="bookNow()"
          class="bg-blue text-white py-2 px-4 h-[50px] md:w-[140px] font-semibold uppercase text-sm rounded-xl absolute right-[15px] top-[15px] hidden sm:block"
        >
          Book Now
        </button>

        <input
          @keyup.enter="bookNow()"
          v-model="postcode"
          class="border border-blue-light-one rounded-xl h-[80px] w-full pl-5 bg-white text-base"
          type="text"
          placeholder="Enter your postcode"
        />
      </div>

      <p class="mt-3 text-red">{{ error }}</p>

      <button
        @click="bookNow()"
        class="bg-blue text-white py-2 px-4 h-[50px] w-full max-w-md font-semibold uppercase text-sm rounded-xl relative z-20 mt-3 block sm:hidden"
      >
        Book Now
      </button>
    </div>
  </div>
</template>
<script setup>
import { storeToRefs } from "pinia";

const props = defineProps({
  type: String,
  equipmentTypeId: String,
  widgetKey: String,
});

const loading = ref(false);
const error = ref();
const postcode = ref();
const store = useBookingStore();
const { booking } = storeToRefs(store);
const { generateBooking, setPostcode, canBeOnPage } = store;

if (process.client) {
  window.onpopstate = function () {
    loading.value = false;
  };
  // this.pageIsReady();
}

async function bookNow() {
  loading.value = true;

  try {
    await generateBooking(props.widgetKey);
    await setPostcode(postcode.value);

    if (!props.equipmentTypeId) {
      navigateTo({
        path: "/booking/category",
      });
    }
  } catch (e) {
    loading.value = false;
    error.value = e.message;
  }
}
</script>
